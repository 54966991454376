<template>
  <div class="basic-data-list">
    <el-card class="common-search-card">
      <div class="search-input">
        <el-input
          v-model="formModel.materialsUpDownBillNum"
          placeholder="lang.gles.orderManage.pleaseInputNo"
          class="input-with-select"
        >
          <el-button slot="append" type="primary" @click="onSearch(formModel)">
            {{ $t('lang.gles.common.query') }}
          </el-button>
        </el-input>
      </div>
      <el-button @click="moreQueryVisible = true">
        {{ $t('lang.gles.common.moreQuery') }}
      </el-button>
    </el-card>
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button> -->
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableConfig"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.materialsUpDownBillNum }}
          </span>
        </template>
        <template #cancelSlot="{ row, item }">
          <m-confirm-btn
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="![0, 11, 12, 41, 42].includes(row.billStatus)"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 更多查询 -->
    <more-query
      :query-list="moreQueryForm"
      :visible.sync="moreQueryVisible"
      @query="handleMoreQuery"
    />
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :options="detailOptions"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MoreQuery from '@/components/moreQuery'
import DetailDialog from './detail.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import {
  getMoreQueryFormData,
  getSearchTableItem
} from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import orderManageMixins from '@/mixins/orderManageMixins'
import myTransform from '@/libs_sz/utils/transform'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UpAndDownMaterialOrder',
  components: {
    ColumnConfig,
    MoreQuery,
    MTable,
    DetailDialog
  },
  mixins: [listMixins, orderManageMixins],
  data() {
    return {
      detailDialogVisible: false,
      tableData: [],
      formModel: {},
      tableConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '80px',
        operate: [
          /* {
            event: 'cancelItem',
            label: this.$t('lang.gles.workflow.taskStatus.cancel'),
            slotName: 'cancelSlot',
            confirm: true,
            confirmMessage: this.$t('lang.gles.strategy.strategy.isSureCancel')
          } */
        ]
      },
      // 工厂
      factoryList: [],
      // 车间
      workshopList: [],
      // 仓库
      warehouseList: [],
      // 库区
      warehouseAreaList: [],
      // 库位
      factoryPositionList: [],
      // 区域
      areaList: [],
      // 产线
      productionLineList: [],
      // 设备
      deviceList: [],
      // 设备货位
      goodsPositionList: [],
      // 容器类型
      containerTypeList: [],
      // 周转容器类型
      turnoverContainerTypeList: [],
      // 物料编码
      materialCodeList: [],
      // 物料名称
      materialNameList: [],
      // 所有容器编码
      containerArchivesAllList: []
    }
  },
  computed: {
    ...mapState('base', [
      'materialsBillStatusList',
      'materialsBillReceiptTypeList',
      'taskStatusList',
      'robotWorkTypeList',
      'operatingModeList',
      'subTaskTypeList',
      'gradeStatusList'
    ]),
    ...mapState('materialManage', ['basicUnitList']),
    ...mapState('order', ['checkStatusList']),
    ...mapState('workflow', ['executeModeList']),
    moreQueryForm() {
      return getMoreQueryFormData(this, this.formModel)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    detailOptions() {
      const options = {
        factoryList: this.factoryList,
        workshopList: this.workshopList,
        warehouseList: this.warehouseList,
        warehouseAreaList: this.warehouseAreaList,
        factoryPositionList: this.factoryPositionList,
        areaList: this.areaList,
        productionLineList: this.productionLineList,
        deviceList: this.deviceList,
        goodsPositionList: this.goodsPositionList,
        materialsBillStatusList: this.materialsBillStatusList,
        materialsBillReceiptTypeList: this.materialsBillReceiptTypeList,
        containerTypeList: this.containerTypeList,
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        materialCodeList: this.materialCodeList,
        materialNameList: this.materialNameList,
        containerArchivesAllList: this.containerArchivesAllList,
        gradeStatusList: this.gradeStatusList,
        taskStatusList: this.taskStatusList,
        robotWorkTypeList: this.robotWorkTypeList,
        basicUnitList: this.basicUnitList,
        operatingModeList: this.operatingModeList,
        subTaskTypeList: this.subTaskTypeList,
        checkStatusList: this.checkStatusList,
        executeModeList: this.executeModeList
      }
      return options
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    // this.addUpDownMaterialsBill()
    this.initSelectList()
    this.doSearch(this.formModel)
  },
  methods: {
    ...mapActions('materialManage', ['getBasicUnitList']),
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getBaseData('getFactoryDictList', 'factoryList', 'factoryCode')
      this.getBaseData('getWarehouseDictList', 'warehouseList', 'warehouseCode')
      this.getBaseData('getWorkshopDictList', 'workshopList', 'workshopCode')
      this.getBaseData('getAreaDictList', 'areaList', 'areaCode')
      this.getBaseData(
        'getProductionLineDictList',
        'productionLineList',
        'productionLineCode'
      )
      this.getBaseData(
        'getWarehouseAreaDictList',
        'warehouseAreaList',
        'warehouseAreaCode'
      )
      this.getBaseData(
        'getFactoryPositionDictList',
        'factoryPositionList',
        'factoryPositionCode'
      )
      this.getBaseData('getDeviceDictList', 'deviceList', 'deviceCode')
      this.getBaseData(
        'getGoodsPositionListByType',
        'goodsPositionList',
        'goodsPositionCode'
      )
      this.getContainerTypeList([], 'containerTypeList')
      this.getContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'turnoverContainerTypeList'
      )
      this.getMaterialCodeList()
      this.getContainerArchivesAllList()
      if (!this.materialsBillStatusList?.length) {
        this.$store.dispatch('base/getMaterialsBillStatusList')
      }
      if (!this.materialsBillReceiptTypeList?.length) {
        this.$store.dispatch('base/getMaterialsBillReceiptTypeList')
      }
      if (!this.taskStatusList?.length) {
        this.$store.dispatch('base/getTaskStatusList')
      }
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('base/getRobotWorkTypeList')
      }
      if (!this.operatingModeList?.length) {
        this.$store.dispatch('base/getOperatingModeList')
      }
      if (!this.subTaskTypeList?.length) {
        this.$store.dispatch('base/getSubTaskTypeList')
      }
      if (!this.checkStatusList?.length) {
        this.$store.dispatch('order/getCheckStatusList')
      }
      if (!this.executeModeList?.length) {
        this.$store.dispatch('workflow/getExecuteModeList')
      }
      if (!this.basicUnitList?.length) {
        this.getBasicUnitList()
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
    },
    // 获取容器类型
    async getContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.containerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    /**
     * 新增
     */
    updateCom({ row }) {
      this.$emit('updateCom', {
        currentCom: 'WarehousingOrderDetail',
        mode: 'add',
        row
      })
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    /**
     * 更多查询
     */
    handleMoreQuery(event) {
      this.formModel = { ...event }
      if (event.createTime && event.createTime.length) {
        event.createTimeStart = event.createTime[0]
        event.createTimeEnd = event.createTime[1]
      }
      if (event.updateTime && event.updateTime.length) {
        event.updateTimeStart = event.updateTime[0]
        event.updateTimeEnd = event.updateTime[1]
      }
      if (event.completedTime && event.completedTime.length) {
        event.completedTimeStart = event.completedTime[0]
        event.completedTimeEnd = event.completedTime[1]
      }
      const obj = {
        ...event
      }
      delete obj.createTime
      delete obj.updateTime
      delete obj.completedTime
      this.pageData.currentPage = 1
      this.doSearch({ ...obj }, 'queryUpDownMaterialsBillByPage')
    },
    async addUpDownMaterialsBill() {
      const params = {
        upDownMaterialsBillBase: {
          areaId: 1,
          billStatus: 0,
          completedTime: null,
          createTime: null,
          createUser: 'admin',
          deleteFlag: null,
          deviceId: 1,
          exceptionDesc: 123,
          factoryId: 1,
          factoryPositionId: 1,
          goodsPositionId: 1,
          materialsUpDownBillNum: 'DJ000000022',
          outerFlag: 1,
          productionLineId: 1,
          receiptType: 1,
          remark: 1,
          updateTime: null,
          updateUser: null,
          warehouseAreaId: 1,
          warehouseId: 1,
          workshopId: 1
        },
        upDownMaterialsBillContainers: [
          {
            completedTime: null,
            containerArchivesId: 1,
            containerNumber: 1,
            containerTypeId: 1,
            createTime: null,
            createUser: null,
            deleteFlag: null,
            factoryPositionId: 1,
            goodsPositionId: 1,
            materialsType: 2,
            materialsUpDownBillNum: 'DJ000000022',
            updateTime: null,
            updateUser: null,
            warehouseAreaId: 1,
            upDownMaterialsBillContainerDetails: [
              {
                completedTime: null,
                containerArchivesId: 1,
                containerNumber: 2,
                containerTypeId: 1,
                createTime: null,
                createUser: null,
                degreeStatus: null,
                deleteFlag: null,
                factoryPositionId: 1,
                goodsPositionId: 1,
                materialsCode: 1,
                materialsDirection: 1,
                materialsName: 1,
                materialsId: 1,
                materialsNumber: 1,
                materialsType: 2,
                materialsTypeLevel: 1,
                materialsTypeLevelSec: 1,
                materialsUnit: 1,
                materialsUpDownBillNum: 'DJ000000022',
                measureNumber: 1,
                measureUnit: null,
                parentGoodsPositionId: 1,
                upDownMaterialsBillContainerId: 1,
                updateTime: null,
                updateUser: null,
                warehouseAreaId: 1
              }
            ]
          }
        ],
        upDownMaterialsBillContainerDetails: [
          {
            completedTime: null,
            containerArchivesId: 1,
            containerNumber: 2,
            containerTypeId: 1,
            createTime: null,
            createUser: null,
            degreeStatus: null,
            deleteFlag: null,
            factoryPositionId: 1,
            goodsPositionId: 1,
            materialsCode: 1,
            materialsDirection: 1,
            materialsName: 1,
            materialsId: 1,
            materialsNumber: 1,
            materialsType: 2,
            materialsTypeLevel: 1,
            materialsTypeLevelSec: 1,
            materialsUnit: 1,
            materialsUpDownBillNum: 'DJ000000021',
            measureNumber: 1,
            measureUnit: null,
            parentGoodsPositionId: 1,
            upDownMaterialsBillContainerId: 1,
            updateTime: null,
            updateUser: null,
            warehouseAreaId: 1
          }
        ]
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('addUpDownMaterialsBill'),
        params
      )
      console.log(data)
    },
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.doSearch(this.formModel)
    },
    onSearch() {
      this.pageData.currentPage = 1
      this.doSearch(this.formModel)
    },
    /**
     * 接口查询
     */
    async doSearch(params = {}, api = 'queryUpDownMaterialsBillByPage') {
      try {
        params.outerFlag = 1
        const { data, code } = await this.$httpService(
          this.$apiStore.orderManage(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount: totalRecordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 取消
     */
    cancelItem({ row }) {
      // console.log(row)
      const params = {
        receiptNo: row.materialsUpDownBillNum,
        receiptType: row.receiptType
      }
      this.$httpService(
        this.$apiStore.monitorManage('postCancel'),
        params
      ).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    _operateClick(event, row) {
      this[event]({ row })
    }
  }
}
</script>
